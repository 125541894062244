<template>
  <div class="app" id="app">
    <!-- <router-view v-wechat-title='$route.meta.title'/> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" v-wechat-title='$route.meta.title' />
  </div>
</template>
<script>
  // import Vue from "vue";
  // import VueRouter from "vue-router";
  // import { getPermissionsList } from "@/api/electronicCard";
  // import store from "@/store";
  export default {
    // created() {
    //   // 获取应用权限
    //   getPermissionsList().then((res) => {
    //     store.commit("SET_INIT_PERMISSION", res.data);
    //   });
    // },
  };
</script>
<style lang="less">
  @import "./assets/css/style";

  * {
    box-sizing: border-box !important;
  }

  html {
    font-size: 85px !important;
    height: 100%;
  }

  body {
    font-size: 24px !important;
    background: #f7f7f7 !important;
  }

  .app {
    width: 100%;
    max-width: 500px;
    background: #fff;
    border-radius: 8px;
    margin: 0 auto;
    height: 100%;
  }

  .van-popup {
    max-width: 500px;
    left: unset !important;
  }

  .van-dropdown-item {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 10;
    overflow: hidden;
    top: 70.031px;
    height: 999px;
  }
</style>