import Vue from "vue";
import VueRouter from "vue-router";
import {
  checkLogin,
  getCookie,
  setCookie
} from "@/plugins/utils";
// import beforeLogin from '@/views/beforeLogin'
//解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/beforeLogin",
  //   name: "beforeLogin",
  //   component: beforeLogin,
  //   meta: { title: "企业微信授权" },
  // },
  {
    path: "/",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/electronicCard/index"),
    meta: {
      title: "首页",
      keepAlive: true,
      index: 0
    },
  },
  {
    path: "/index/index",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/index/index"),
    meta: {
      title: "代办事项"
    },
  },
  {
    path: "/index/create",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/index/create"),
    meta: {
      title: "首页"
    },
  },
  {
    path: "/contact/index",
    component: () =>
      import( /* webpackChunkName: "contact" */ "@/views/contact/index"),
    meta: {
      title: "客户页",
      index: 1
    },
  },
  {
    path: "/contact/screen",
    component: () =>
      import( /* webpackChunkName: "contact" */ "@/views/contact/screen"),
    meta: {
      title: "客户页"
    },
  },
  {
    path: "/contact/detail",
    component: () =>
      import( /* webpackChunkName: "contact" */ "@/views/contact/detail"),
    meta: {
      title: "我的"
    },
  },
  {
    path: "/my",
    component: () => import( /* webpackChunkName: "my" */ "@/views/my/index"),
    meta: {
      title: "我的"
    },
  },
  {
    path: "/my/contact",
    component: () => import( /* webpackChunkName: "my" */ "@/views/my/contact"),
    meta: {
      title: "联系"
    },
  },
  {
    path: "/my/mySummary",
    component: () =>
      import( /* webpackChunkName: "my" */ "@/views/my/mySummary"),
    meta: {
      title: "我的小结"
    },
  },
  {
    path: "/my/myIntegral",
    component: () => import( /* webpackChunkName: "my" */ "@/views/my/myIntegral"),
    meta: {
      title: "我的积分"
    },
  },
  {
    path: "/my/IntegralRule",
    component: () => import( /* webpackChunkName: "my" */ "@/views/my/IntegralRule"),
    meta: {
      title: "积分规则"
    },
  },
  {
    path: "/poster/index",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/poster/index"),
    meta: {
      title: "海报"
    },
  },
  {
    path: "/shareDraw/index",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/shareDraw/index"),
    meta: {
      title: "抽奖"
    },
  },
  {
    path: "/poster/create",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/poster/create"),
    meta: {
      title: "创建海报"
    },
  },
  {
    path: "/my/feedBack",
    component: () => import( /* webpackChunkName: "my" */ "@/views/my/feedBack"),
    meta: {
      title: "意见反馈"
    },
  },
  //  {
  //    path: "/my/feedBackNew",
  //    component: () =>
  //      import(/* webpackChunkName: "my" */ "@/views/my/feedBackNew"),
  //    meta: { title: "意见反馈" },
  //  },
  {
    path: "/tool/index",
    component: () =>
      import( /* webpackChunkName: "tool" */ "@/views/tool/index2"),
    meta: {
      title: "营销台",
    },
  },
  {
    path: "/contactStatistic/index",
    component: () =>
      import(
        /* webpackChunkName: "contact" */
        "@/views/contactStatistic/index"
      ),
    meta: {
      title: "客户统计"
    },
  },
  {
    path: "/channelCode/index",
    component: () =>
      import( /* webpackChunkName: "channelCode" */ "@/views/channelCode/index"),
    meta: {
      title: "渠道活码"
    },
  },
  {
    path: "/channelCode/create",
    component: () =>
      import(
        /* webpackChunkName: "channelCode" */
        "@/views/channelCode/create"
      ),
    meta: {
      title: "新建活码"
    },
  },
  {
    path: "/channelCode/detail",
    component: () =>
      import(
        /* webpackChunkName: "channelCode" */
        "@/views/channelCode/detail"
      ),
    meta: {
      title: "活码统计"
    },
  },
  {
    path: "/contactWelcome/index",
    component: () =>
      import(
        /* webpackChunkName: "contactWelcome" */
        "@/views/contactWelcome/index"
      ),
    meta: {
      title: "我的欢迎语"
    },
  },
  {
    path: "/contactWelcome/create",
    component: () =>
      import(
        /* webpackChunkName: "contactWelcome" */
        "@/views/contactWelcome/create"
      ),
    meta: {
      title: "新建欢迎语"
    },
  },
  {
    path: "/workMoment/index",
    component: () =>
      import( /* webpackChunkName: "workMoment" */ "@/views/workMoment/index"),
    meta: {
      title: "历史朋友圈"
    },
  },
  {
    path: "/workMoment/create",
    component: () =>
      import( /* webpackChunkName: "workMoment" */ "@/views/workMoment/create"),
    meta: {
      title: "新建朋友圈"
    },
  },
  {
    path: "/medium/index",
    component: () =>
      import( /* webpackChunkName: "medium" */ "@/views/medium/index"),
    meta: {
      title: "素材库"
    },
  },
  {
    path: "/medium/detail",
    component: () =>
      import( /* webpackChunkName: "medium" */ "@/views/medium/detail"),
    meta: {
      title: "详情"
    },
  },
  {
    path: "/medium/create",
    component: () =>
      import( /* webpackChunkName: "medium" */ "@/views/medium/create"),
  },
  {
    path: "/roomTemplatePull/index",
    component: () =>
      import(
        /* webpackChunkName: "roomTemplatePull" */
        "@/views/roomTemplatePull/index"
      ),
    meta: {
      title: "一键拉群"
    },
  },
  {
    path: "/roomTemplatePull/create",
    component: () =>
      import(
        /* webpackChunkName: "roomTemplatePull" */
        "@/views/roomTemplatePull/create"
      ),
  },
  {
    path: "/contactDeleteRemind/index",
    component: () =>
      import(
        /* webpackChunkName: "contactDeleteRemind" */
        "@/views/contactDeleteRemind/index"
      ),
    meta: {
      title: "删人提醒"
    },
  },
  {
    path: "/contactLost/index",
    component: () =>
      import( /* webpackChunkName: "contactLost" */ "@/views/contactLost/index"),
    meta: {
      title: "流失客户"
    },
  },
  {
    path: "/sensitiveWordMonitor/index",
    component: () =>
      import(
        /* webpackChunkName: "sensitiveWordMonitor" */
        "@/views/sensitiveWordMonitor/index"
      ),
    meta: {
      title: "关键词提醒"
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import( /* webpackChunkName: "login" */ "@/views/login"),
    meta: {
      title: "登录"
    },
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import( /* webpackChunkName: "auth" */ "@/views/auth"),
  },
  {
    path: "/contactMessageBatchSend/create",
    component: () =>
      import(
        /* webpackChunkName: "contactMessageBatchSend" */
        "@/views/contactMessageBatchSend/create"
      ),
    meta: {
      title: "客户群发"
    },
  },
  {
    path: "/roomMessageBatchSend/create",
    component: () =>
      import(
        /* webpackChunkName: "roomMessageBatchSend" */
        "@/views/roomMessageBatchSend/create"
      ),
    meta: {
      title: "客户群群发"
    },
  },
  {
    path: "/qunfa/sendDetail",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/components/sendDetail"
      ),
    meta: {
      title: "群发明细"
    },
  },
  {
    path: "/contact/business",
    component: () =>
      import( /* webpackChunkName: "contact" */ "@/views/contact/business"),
    meta: {
      title: "对公业务"
    },
  },
  {
    path: "/contact/search",
    component: () =>
      import( /* webpackChunkName: "contact" */ "@/views/contact/search"),
    meta: {
      title: "搜索"
    },
  },
  {
    path: "/contact/selectBinding",
    component: () =>
      import( /* webpackChunkName: "contact" */ "@/views/contact/selectedBinding"),
    // meta: { title: "搜索" },
  },
  {
    path: "/payPopu/index",
    component: () =>
      import( /* webpackChunkName: "index" */ "@/views/payPopu/index"),
    meta: {
      title: "支付跳转页面"
    },
  },
  {
    path: "/electronicCard/personInfo",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/personInfo"
      ),
    meta: {
      title: "个人信息"
    },
  },
  {
    path: "/electronicCard/index",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/index"
      ),
    meta: {
      title: "金融工作室"
    },
  },
  {
    path: "/electronicCard/caijing",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/caiJing"
      ),
    meta: {
      title: "财经资讯"
    },
  },
  {
    path: "/electronicCard/caijing/detail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/components/caiJingDetail"
      ),
    meta: {
      title: "资讯详情"
    },
  },
  {
    path: "/electronicCard/activity",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/activity"
      ),
    meta: {
      title: "热门活动"
    },
  },
  {
    path: "/electronicCard/activity/detail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/components/activityDetail"
      ),
    meta: {
      title: "活动详情"
    },
  },
  {
    path: "/electronicCard/financialinfo",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/financialinfo"
      ),
    meta: {
      title: "金融产品"
    },
  },
  {
    path: "/electronicCard/financialinfo/detail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/components/financialStatic"
      ),
    meta: {
      title: "产品详情"
    },
  },
  {
    path: "/electronicCard/notice",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/notice"
      ),
    meta: {
      title: "通知"
    },
  },
  {
    path: "/electronicCard/notice/detail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/components/noticeDetail"
      ),
    meta: {
      title: "通知详情"
    },
  },
  {
    path: "/helpCenter/index",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/helpCenter/index"
      ),
    meta: {
      title: "帮助中心"
    },
  },
  {
    path: "/helpCenter/search",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/helpCenter/search"
      ),
    meta: {
      title: "帮助中心-搜索"
    },
  },
  {
    path: "/updateLog/index",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/updateLog/index"
      ),
    meta: {
      title: "更新日志"
    },
  },
  {
    path: "/electronicCard/morningPaper/index",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/morningPaperIndex"
      ),
    meta: {
      title: "早报"
    },
  },
  {
    path: "/electronicCard/morningPaper/setting",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/morningPaperSet"
      ),
    meta: {
      title: "早报设置"
    },
  },
  {
    path: "/electronicCard/morningPaperIndex/setting",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/morningPaperIndexSet"
      ),
    meta: {
      title: "设置"
    },
  },
  {
    path: "/electronicCard/morningPaper/selectDaodu",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/components/selectDaodu"
      ),
    meta: {
      title: "选择导读"
    },
  },
  {
    path: "/electronicCard/eveningPaper/index",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/eveningPaper"
      ),
    meta: {
      title: "晚报"
    },
  },
  {
    path: "/electronicCard/eveningPaper/setting",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/eveningPaperSet"
      ),
    meta: {
      title: "晚报设置"
    },
  },
  {
    path: "/electronicCard/specialTopic",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/specialTopic"
      ),
    meta: {
      title: "专题"
    },
  },
  {
    path: "/electronicCard/specialTopicDetail",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/components/specialTopicDetail"
      ),
    meta: {
      title: "专题详情"
    },
  },
  {
    path: "/electronicCard/circle",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/circle"
      ),
    meta: {
      title: "朋友圈"
    },
  },
  {
    path: "/electronicCard/circleHistory",
    component: () =>
      import( /* webpackChunkName: "workMoment" */ "@/views/electronicCard/circleHistory"),
    meta: {
      title: "历史朋友圈"
    },
  },
  {
    path: "/electronicCard/circleSet",
    component: () =>
      import(
        /* webpackChunkName: "eletronicCard" */
        "@/views/electronicCard/components/circleSet"
      ),
    meta: {
      title: "朋友圈设置"
    },
  },
  {
    path: "/contactRadar/index",
    component: () =>
      import(
        /* webpackChunkName: "contactRadar" */
        "@/views/contactRadar/index"
      ),
    meta: {
      title: "客户雷达"
    },
  },
  {
    path: "/socialinteraction/index",
    component: () =>
      import(
        /* webpackChunkName: "contactRadar" */
        "@/views/socialinteraction/index"
      ),
    meta: {
      title: "社群互动"
    },
  },
  {
    path: "/appointment/index",
    component: () =>
      import(
        /* webpackChunkName: "appointment" */
        "@/views/appointment/index"
      ),
    meta: {
      title: "预约处理"
    },
  },
  {
    path: "/appointment/detail",
    component: () =>
      import(
        /* webpackChunkName: "appointment" */
        "@/views/appointment/detail"
      ),
    meta: {
      title: "预约处理"
    },
  },
  {
    path: "/xiaoban/index",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/xiaoBan/index"
      ),
    meta: {
      title: "小班问答"
    },
  },
  {
    path: "/xiaoban/recharge",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/xiaoBan/recharge"
      ),
    meta: {
      title: "小班问答充值"
    },
  },
  {
    path: "/xiaoban/qaRecord",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/xiaoBan/qaRecord"
      ),
    meta: {
      title: "小班问答记录"
    },
  },
  {
    path: "/lottery/index",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/lottery/index"
      ),
    meta: {
      title: "兑奖核销"
    },
  },
  {
    path: "/qunfa/index",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/index"
      ),
    meta: {
      title: "群发",
      keepAlive: true,
    },
  },
  {
    path: "/qunfa/selectWay",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/components/selectWay"
      ),
    meta: {
      title: "新建群发"
    },
  },
  {
    path: "/qunfa/create",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/components/create"
      ),
    meta: {
      title: "新建群发"
    },
  },
  {
    path: "/qunfa/customstatistics",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/Customstatistics"
      ),
    meta: {
      title: "群发客户统计",
    },
  },
  {
    path: "/qunfa/qunfaDetail",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/components/qunfaDetail"
      ),
    meta: {
      title: "群发客户详情"
    },
  },
  {
    path: "/qunfa/sendRange",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/components/sendRange"
      ),
    meta: {
      title: "群发客户范围"
    },
  },
  {
    path: "/qunfa/kehuqunStatistic",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/kehuqunStatistic"
      ),
    meta: {
      title: "群发客户统计"
    },
  },
  {
    path: "/qunfa/directSend",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/components/directSend"
      ),
    meta: {
      title: "发送给我的客户"
    },
  },
  {
    path: "/qunfa/circleStatic",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/circleStatic"
      ),
    meta: {
      title: "群发朋友圈统计"
    },
  },
  {
    path: "/qunfa/selectRange",
    component: () =>
      import(
        /* webpackChunkName: "qunfa" */
        "@/views/qunfa/components/selectRange"
      ),
    meta: {
      title: "筛选发送对象"
    },
  },
  {
    path: "/sopList/index",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/sopList/index"
      ),
    meta: {
      title: "SOP"
    },
  },
  {
    path: "/sopList/selectWay",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/sopList/components/selectWay"
      ),
    meta: {
      title: "SOP"
    },
  },
  {
    path: "/sopList/chatPage",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/sopList/components/chatPage"
      ),
    meta: {
      title: "SOP"
    },
  },
  {
    path: "/sopList/hairPage",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/sopList/components/hairPage"
      ),
    meta: {
      title: "SOP"
    },
  },
  {
    path: "/sopList/sopDetail",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/sopList/sopDetail"
      ),
    meta: {
      title: "SOP详情"
    },
  },
  {
    path: "/channelCode/stylePage",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/channelCode/components/stylePage"
      ),
    meta: {
      title: "活码样式"
    },
  },
  {
    path: "/dataBurialPoint/index",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/dataBurialPoint/index"
      ),
    meta: {
      title: "数据看版"
    },
  },
  {
    path: "/visitorRecord/index",
    component: () =>
      import(
        /* webpackChunkName: "xiaoban" */
        "@/views/electronicCard/components/visitorRecord"
      ),
    meta: {
      title: "访客记录"
    },
  },
  {
    path: "/festival/index",
    component: () =>
      import(
        "@/views/festival/index"
      ),
    meta: {
      title: "节点关怀"
    },
  },
  {
    path: "/festival/festivalList",
    component: () =>
      import(
        "@/views/festival/components/festivalList"
      ),
    meta: {
      title: "节日问候"
    },
  },
  {
    path: "/festival/festivalUse",
    component: () =>
      import(
        "@/views/festival/components/festivalUse"
      ),
    meta: {
      title: "节日详情"
    },
  },
  {
    path: "/statistics/detail",
    component: () =>
      import(
        "@/views/statistics/DownPage"
      ),
    meta: {
      title: "数据详情"
    },
  },
  {
    path: "/externalClock/index",
    component: () =>
      import(
        "@/views/externalClock/index"
      ),
    meta: {
      title: "外拓打卡"
    },
  },
  {
    path: "/externalClock/createClock",
    component: () =>
      import(
        "@/views/externalClock/createClock"
      ),
    meta: {
      title: "打卡"
    },
  },
  {
    path: "/externalClock/clockDetail",
    component: () =>
      import(
        "@/views/externalClock/clockDetail"
      ),
    meta: {
      title: "打卡详情"
    },
  },
  {
    path: "/workOrder/index",
    component: () =>
      import(
        "@/views/workOrder/index"
      ),
    meta: {
      title: "我的工单"
    },
  },
  {
    path: "/workOrder/create",
    component: () =>
      import(
        "@/views/workOrder/create"
      ),
    meta: {
      title: "新增工单"
    },
  },
  {
    path: "/workOrder/statistics",
    component: () =>
      import(
        "@/views/workOrder/statistics"
      ),
    meta: {
      title: "微工单"
    },
  },
  {
    path: "/workOrder/detail",
    component: () =>
      import(
        "@/views/workOrder/detail"
      ),
    meta: {
      title: "工单详情"
    },
  },
  {
    path: "/workOrder/statistics",
    component: () =>
      import(
        "@/views/workOrder/statistics"
      ),
    meta: {
      title: "工单统计"
    },
  },
  {
    path: "/workOrder/workOrderPool",
    component: () =>
      import(
        "@/views/workOrder/workOrderPool"
      ),
    meta: {
      title: "工单池"
    },
  },
  {
    path: "/workOrder/myOrder",
    component: () =>
      import(
        "@/views/workOrder/myOrder"
      ),
    meta: {
      title: "我的工单"
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = '翠域精灵'
  try {
    // let agentId = to.query.agentId
    // if (localStorage.getItem("num")!=1) {
    //   console.log('123, localStorage.getItem("num")', localStorage.getItem("num"))
    //   console.log('to.query.agentId122',agentId)
    //   localStorage.setItem("num", 1)
    //   next({
    //     path: "/beforeLogin",query: { agentId },
    //   })
    // } else {
    //   console.log('jfdfdf')
    if (checkLogin(to, from, next) === false) {
      let agentId = to.query.agentId;
      if (!agentId) {
        agentId = getCookie("workbench_agentId");
      }
      next({
        path: "/login",
        query: {
          agentId: agentId,
          target: to.fullPath
        },
      });
    } else {
      let agentId = to.query.agentId;
      if (agentId) {
        setCookie('workbench_agentId', agentId, 86400)
      }
      next();
    }
    // }

  } catch (e) {
    next({
      path: "/"
    });
  }
});

export default router;