import Vue from 'vue'
import store from "@/store";
const directive = Vue.directive('permission', {
  inserted: function (el, binding, vnode) {
    const action = binding.value
    // const actionList = store.getters.permission
    const actionList = JSON.parse(localStorage.getItem('SET_INIT_PERMISSION'));
    if (action === "") {
      return; // 如果权限值为空，直接返回，不进行权限判断
    }

    if (actionList) {
      if (!actionList.includes(action)) {
        (el.parentNode && el.parentNode.removeChild(el)) || (el.style.display = 'none')
      }
    } else {
      (el.parentNode && el.parentNode.removeChild(el)) || (el.style.display = 'none')
    }
  }
})
export default directive