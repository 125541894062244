
const app = {
  state: {
    initAgentConfig: false,
    initAgentConfig2: false,
    permission:undefined
  },
  mutations: {
    SET_INIT_AGENT_CONFIG: (state, data) => {
      state.initAgentConfig = data
    },
    SET_INIT_AGENT_CONFIG2: (state, data) => {
      state.initAgentConfig2 = data
    },
    //设置当前应用权限
    SET_INIT_PERMISSION:(state, data) => {
      state.permission = data
    },
  },
  actions: {
  }
}

export default app
