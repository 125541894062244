import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as echarts from "echarts";

import "./plugins/axios";
import "./plugins/vant";
import "./plugins/antd";
import "./plugins/utils";
import "./plugins/components";
import "./plugins/amfe";
import "amfe-flexible";
import './utils/permissions';
import More from '@/components/More/More'

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole);
// import VueWechatTitle from 'vue-wechat-title'//动态修改title
// Vue.use(VueWechatTitle)
Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.component('More', More)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
